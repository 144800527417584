
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import moment from 'moment';
import { getTfseList, updateSecretStatus } from '@/api/tfse';

@Component({
	components: {
	},
})

export default class extends Vue {
	mounted() {
		this.getTfseList();
		if (this.$route.query.tfseDate) {
			this.tfseDate = this.$route.query.tfseDate;
		} else {
			this.$router.replace({ ...this.$router, query: { ...this.$route.query, tfseDate: this.tfseDate } });
		}
		// if (this.$route.query.tab) this.type = this.$route.query.tab;
	}

	// 라우트가 변경될 때마다 호출되는 네비게이션 가드
	beforeRouteUpdate(to: Route, from: Route, next: () => void) {
		this.getTfseList();
		next();
	}

	private loading = true;

	private tfseDate: any = this.$route.query.tfseDate ? this.$route.query.tfseDate : moment().format('YYYY-MM-DD');

	private listQuery = {
		searchDate: this.tfseDate,
	}

	private tfseData: any = [];

	// private type: any = 'diary';

	private handleAddDay(days: number) {
		this.tfseDate = moment(this.tfseDate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
		this.listQuery.searchDate = this.tfseDate;
		this.$router.replace({ ...this.$router, query: { tfseDate: this.tfseDate } });
		this.getTfseList();
	}

	private updateSecretStatus(idx: number, secretStatus: boolean) {
		this.loading = true;
		secretStatus = !secretStatus;
		updateSecretStatus({ idx, secretStatus }).then((res) => {
			this.loading = false;
		});
	}

	private getTfseList() {
		getTfseList(this.listQuery).then((res) => {
			this.tfseData = res.data;
			this.loading = false;
		});
	}

	private editTfse(idx: any) {
		this.$router.push({ name: 'TfseUpdate', params: { tfseIdx: idx } });
	}

	private writeHandler() {
		if (this.tfseData.length >= 6) {
			this.$message.info('최대 6개까지 작성이 가능합니다');
		} else this.$router.push({ name: 'TfseWrite', query: { tfseDate: this.tfseDate } });
	}
}
